import React from "react"
import { Delivery, DeliveryStatus } from "../../../../types"
import moment from "moment"
import { numberWithCommas } from "../../../../functions"

const styles = require("./style.module.scss")

interface DeliveryItemProps {
  delivery: Delivery
  index: number
  isSelected: boolean
  onClick: () => void
}

const DeliveryItem = ({ delivery, index, isSelected, onClick }: DeliveryItemProps) => {
  function getDeliverySum() {
    let sum = 0

    if (delivery) {
      delivery.items?.forEach(item => {
        sum += (item.product.eventPrice || item.product.price) * item.count
      })
    }

    return numberWithCommas(sum)
  }

  function getDeliveryStatusText() {
    switch (delivery && delivery.status) {
      case "ORDERED":
        return "주문 접수"
      case "OUT_FOR_DELIVERY":
        return "배송중"
      case "DELIVERIED":
        return "배송완료"
      case "CANCELLED":
        return "취소됨"
      default:
        break
    }
  }

  return (
    <div
      className={styles[`container${isSelected ? "Selected" : ""}`] + " h-box"}
      onClick={onClick}
    >
      <div className="v-box">
        <span className={styles[`date${isSelected ? "Selected" : ""}`]}>
          {delivery && moment(delivery.orderDate).format("YYYY/MM/DD")}
        </span>
        <span className={styles[`number${isSelected ? "Selected" : ""}`]}>#{index + 1}</span>
      </div>
      <div className="v-box">
        <span className={styles[`priceSum${isSelected ? "Selected" : ""}`]}>
          {getDeliverySum()}
        </span>
        <span className={styles[`count${isSelected ? "Selected" : ""}`]}>
          {delivery && delivery.items?.length}개 항목
        </span>
      </div>
      <div className="v-box" style={{ alignItems: "flex-end", justifyContent: "center" }}>
        <span className={styles[`status${isSelected ? "Selected" : ""}`]}>
          {getDeliveryStatusText()}
        </span>
      </div>
    </div>
  )
}

export default DeliveryItem
